<script>
    import {createEventDispatcher} from 'svelte';
    import {isToday, isPast} from 'date-fns';
    import Tooltip from "../UI/Tooltip.svelte";
    import Icono from "../UI/Icono.svelte";
    import TooltipDeTipo from "./TooltipDeTipo.svelte";

    export let fecha;
    export let tipo;
    export let seleccionado = false;
    export let seleccionable = false;
    export let marcado = false;

    let tooltip = false;

    $: hoy = isToday(fecha);
    $: anterior = isPast(fecha);
    $: numero = fecha.getDate();

    const dispatch = createEventDispatcher();
    const clickEnDia = e => {
      dispatch('click-en-dia', {fecha, shift: e.shiftKey, ctrl: e.metaKey || e.ctrlKey});
    };
</script>

<style>
    .dia {
        --color: var(--negro);
        text-align: center;
        padding: .25em;
        border: 1px solid transparent;
        outline: none;
        position: relative;
        color: #fff;
        font-family: inherit;
        font-size: inherit;
    }

    .dia:after {
        content: '';
        background: rgba(0, 0, 0, .3);
        position: absolute;
        top: -1px;
        left: -1px;
        right: -1px;
        bottom: -1px;
        pointer-events: none;
        opacity: 0;
    }

    .marcado,
    .dia:focus,
    .dia:hover {
        box-shadow: 0 0 4px 2px rgba(0, 0, 0, .2);
        position: relative;
        z-index: 10;
    }

    .hoy {
        border: 2px solid rgba(255, 255, 255, .7);
    }

    .seleccionado:after {
        opacity: 1;
    }

    .titulo {
        display: flex;
        justify-content: center;
        align-items: center;
    }
</style>

<button class="dia" class:seleccionable class:hoy class:anterior class:seleccionado
        on:click={clickEnDia} style="background: {tipo.color}" class:marcado
        on:focus={() => tooltip = true} on:blur={() => tooltip = false}
        on:mouseover={() => tooltip = true} on:mouseleave={() => tooltip = false}>
    {numero}

    {#if tooltip}
        <TooltipDeTipo {tipo}/>
    {/if}
</button>