<script>
    import Caja from "../UI/Caja.svelte";
    import Subtitulo from "../UI/Subtitulo.svelte";
    import Duracion from "../UI/Duracion.svelte";
    import Icono from "../UI/Icono.svelte";

    export let calendario;

    let laborables = 0, minutos = 0, vacaciones = 0;
    $: if (calendario) {
        const dias = Object.keys(calendario.meses[2]).length === 29 ? 366 : 365;

        [laborables, minutos, vacaciones] =
                Object.values(calendario.meses).reduce(([lab, min, vac], mes) => {
                    const dias = Object.values(mes);

                    return [
                        lab + dias.filter(dia => dia.duracion).length,
                        min + dias.reduce((suma, dia) => suma + (dia.duracion || 0), 0),
                        vac + dias.filter(dia => dia.tipo === 'vacaciones').length
                    ]
                }, [0, 0, 0]);
    }
</script>

<style>
    h2 {
        text-transform: uppercase;
        font-weight: 400;
        font-size: .85em;
        letter-spacing: .15em;
        margin: 0;
    }

    dl {
        margin: -.25em 0 0;
        padding: 0;
        line-height: 2;
        --color: var(--acentos);
    }

    dt {
        font-weight: bold;
        display: inline;
    }

    dt:after {
        content: ':';
        margin-right: .5em;
    }

    dd {
        margin: 0;
        padding: 0;
        display: inline;
    }

    dd:not(:last-child):after {
        content: '\A';
        white-space: pre;
    }

    .bloque {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: var(--padding-global);
    }
</style>

<Caja>
    <div slot="titulo">
        <Subtitulo>Estado</Subtitulo>
    </div>

    <div class="bloque">
        <h2>Laborables</h2>
        <dl>
            <dt>Días</dt>
            <dd>{laborables}</dd>
            <dt>Horas</dt>
            <dd>
                <Duracion {minutos}/>
            </dd>
        </dl>

        <h2>Vacaciones</h2>
        <dl>
            <dt>Días</dt>
            <dd>{vacaciones}</dd>
            {#if vacaciones <= 22}
                <dt>Pendientes</dt>
                <dd>{22 - vacaciones}</dd>
            {:else}
                <dt><Icono margen>warning</Icono> Exceso</dt>
                <dd>{vacaciones - 22}</dd>
            {/if}
        </dl>
    </div>
</Caja>