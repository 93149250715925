<script>
    import {createEventDispatcher} from 'svelte';

    import {format} from 'date-fns';
    import {es} from 'date-fns/locale';

    import store from '../../../../../assets/src/Stores/Trabajadores/ver-trabajador-store';

    import Caja from "../../../../../assets/src/Components/UI/Caja.svelte";
    import Loading from "../../../../../assets/src/Components/UI/Loading.svelte";
    import Subtitulo from "../../../../../assets/src/Components/UI/Subtitulo.svelte";
    import Navegacion from "../../../../../assets/src/Components/UI/Navegacion.svelte";

    import Tabla from "./Marcajes/Tabla.svelte";
    import Resumen from "./Marcajes/Resumen.svelte";

    const dispatch = createEventDispatcher();

    let fecha;
    $: fecha = $store.fecha
</script>

<div class="marcajes">
    <Caja>
        <div slot="titulo">
            <Subtitulo icono="check">Marcajes del trabajador</Subtitulo>
        </div>

        <div slot="botones">
            <Navegacion etiqueta="izquierda" on:cambio>
                {format(fecha, 'LLLL yyyy', {locale: es})}
            </Navegacion>
        </div>

        {#if !$store.cargando}
            <Resumen />
            <Tabla />
        {:else}
            <Loading/>
        {/if}
    </Caja>
</div>