import {writable} from "svelte/store";
import {login} from "../Api/login";
import authStore from './auth-store';

const errores = {
  'Invalid credentials.': 'E-mail o contraseña incorrectos'
};

const estadoInicial = {
  enviando: false,
  cargando: false,
  error: null
};

const store = writable(estadoInicial);

export default {
  subscribe: store.subscribe,
  login: (email, clave) => {
    const timeout = setTimeout(() => store.update(valor => ({...valor, cargando: true})), 300);

    store.update(() => ({
      ...estadoInicial,
      enviando: true
    }));

    return login(email, clave)
        .then(resultado => store.update(valor => {
          authStore.set({token: resultado.token, refreshToken: resultado.refresh_token});

          return {
            ...valor,
            enviando: false,
            cargando: false,
            error: null
          };
        }))

        .catch(e => {
          authStore.set({token: null, refreshToken: null});

          store.update(valor => ({
            ...valor,
            error: errores[e.response.data.message] || e.response.data.message,
            cargando: false
          }));

          throw e;
        })

        .finally(() => clearTimeout(timeout));
  }
}