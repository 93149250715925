import {get, writable} from "svelte/store";
import {actualiza, cargaUno, crea} from '../Api/trabajadores';

const estadoInicial = {
  cargando: true,
  error: null,
  trabajador: {}
};

const store = writable(estadoInicial);

export default {
  subscribe: store.subscribe,
  cargar: (id) => {
    const timeout = setTimeout(() => store.update(valor => ({...valor, cargando: true})), 300);

    store.update(() => estadoInicial);

    cargaUno(id)
        .then(trabajador => store.update(valor => ({...valor, trabajador})))
        .catch(e => store.update(valor => ({...valor, error: e})))
        .finally(() => {
          clearTimeout(timeout);
          store.update(valor => ({...valor, cargando: false}));
        });
  },
  actualizaCampo: (campo, valor) => {
    store.update(estado => ({
      ...estado,
      trabajador: {...estado.trabajador, [campo]: valor}
    }));
  },
  guarda() {
    const trabajador = get(store).trabajador;

    const promesa = trabajador.id ? actualiza(trabajador) : crea(trabajador);
    return promesa
        .then(trabajador => store.update(valor => ({...valor, error: false, trabajador})))
        .catch(error => store.update(valor => ({...valor, error})));
  },
  nuevo() {
    store.set({
      cargando: false,
      error: null,
      trabajador: {
        nombre: '',
        apellidos: '',
        nif: '',
        telefono: '',
        codigo: '',
        tipo: '',
        grupo: null
      }
    });
  }
}