import axios from "../axios-control";
import authStore from '../Stores/auth-store';
import {format} from "date-fns";

let token;
authStore.subscribe(valor => token = valor.token);

export const todos = () => axios
    .get('/trabajadores')
    .then(res => res.data)
    .catch(e => {
      if (e.response.status !== 401) {
        throw(e);
      }
    });

export const resumen = () => axios
    .get('/trabajadores/resumen')
    .then(res => res.data)
    .catch(e => {
      if (e.response.status !== 401) {
        throw(e);
      }
    });

export const resumenDeGrupo = grupo => axios
    .get(`/trabajadores/resumen/${grupo}`)
    .then(res => res.data)
    .catch(e => {
      if (e.response.status !== 401) {
        throw(e);
      }
    });

export const eliminar = id => axios
    .delete(`/trabajadores/${id}`)
    .catch(e => {
      if (e.response.status !== 401) {
        throw(e);
      }
    });

export const cargaUno = id => axios
    .get(`/trabajadores/${id}`)
    .then(res => res.data)
    .catch(e => {
      if (e.response.status !== 401) {
        throw(e);
      }
    });

export const actualiza = trabajador => axios
    .put(`/trabajadores/${trabajador.id}`, trabajador)
    .then(res => res.data)
    .catch(e => {
      if (e.response.status !== 401) {
        throw(e);
      }
    });

export const crea = trabajador => axios
    .post(`/trabajadores`, trabajador)
    .then(res => res.data)
    .catch(e => {
      if (e.response.status !== 401) {
        throw(e);
      }
    });

export const marcajes = (id, anio, mes) => axios
    .get(`/trabajadores/${id}/marcajes/${anio}/${mes}`)
    .then(res => res.data)
    .catch(e => {
      if (e.response.status !== 401) {
        throw(e);
      }
    });

export const buscarMarcajes = (inicio, fin, trabajadores, tipoDeIncidencia, motivo, sede, cancelToken) => {
  return axios
      .post(`/marcajes`, {
        inicio: format(inicio, 'yyyy-MM-dd'),
        fin: format(fin, 'yyyy-MM-dd'),
        trabajadores: trabajadores ? trabajadores.map(x => x.id) : null,
        tipoDeIncidencia,
        motivo,
        sede
      }, {cancelToken: cancelToken.token})
      .then(res => res.data)
      .catch(e => {
        if (!e.response || e.response.status !== 401) {
          throw(e);
        }
      });
};

export const actualizaMarcaje = (trabajador, fecha, original, nuevo) => axios
    .put(`/marcajes/${trabajador.id}/${format(fecha, 'yyyy-MM-dd')}/${original.id}`, nuevo)
    .then(res => res.data)
    .catch(e => {
      if (e.response.status !== 401) {
        throw(e);
      }
    });

export const creaMarcaje = (trabajador, fecha, nuevo) => axios
    .post(`/marcajes/${trabajador.id}/${format(fecha, 'yyyy-MM-dd')}`, {
      ...nuevo,
      origen: 'Administración'
    })
    .then(res => res.data)
    .catch(e => {
      if (e.response.status !== 401) {
        throw(e);
      }
    });

export const eliminaMarcaje = (trabajador, fecha, original, nuevo) => axios
    .delete(`/marcajes/${trabajador.id}/${format(fecha, 'yyyy-MM-dd')}/${original.id}/${encodeURIComponent(nuevo.observaciones)}`)
    .then(res => res.data)
    .catch(e => {
      if (e.response.status !== 401) {
        throw(e);
      }
    });