<script>
    import Titulo from "../../../../assets/src/Components/UI/Titulo.svelte";
    import Caja from "../../../../assets/src/Components/UI/Caja.svelte";
    import CampoDeTexto from "../../../../assets/src/Components/UI/CampoDeTexto.svelte";

    import validate from 'validate.js';
    import Boton from "../../../../assets/src/Components/UI/Boton.svelte";
    import {pideClave} from '../../../../assets/src/Api/login';
    import Loading from "../../../../assets/src/Components/UI/Loading.svelte";
    import Error from "../../../../assets/src/Components/UI/Error.svelte";
    import {goto} from '@sveltech/routify';

    const constraints = {
        codigo: {
            presence: {
                allowEmpty: false,
                message: 'es obligatorio'
            }
        },
    };

    let codigo = '';
    let clave = '';
    let cargando = false;
    let error = false;
    let enviado = false;

    $: errores = validate({codigo, clave}, constraints) || {};

    const iniciaSesion = () => {
        cargando = true;
        enviado = false;
        pideClave(codigo)
                .then(res => enviado = res.ok)
                .then(() => {
                    if (!enviado) {
                        throw new Error();
                    }
                })
                .catch(x => error = 'O el código introducido es incorrecto o no tienes e-mail configurado')
                .finally(() => cargando = false);
    };
</script>

<div class="login">
    <Titulo>Inicio de sesión</Titulo>

    {#if !enviado}
        <Caja>
            <div slot="titulo"><h2>Introduce tu código de empleado</h2></div>

            <form on:submit|preventDefault={iniciaSesion}>
                <CampoDeTexto id="codigo" label="Código" value={codigo}
                              icono="alternate_email"
                              valido={!errores.codigo} mensajeDeError={errores.codigo}
                              on:input={e => codigo = e.target.value}/>

                <div style="display: none">
                    <button disabled={Object.keys(errores).length && !cargando}
                            type="submit"></button>
                </div>
            </form>

            <div slot="pie">
                {#if cargando}
                    <Loading/>
                {:else}
                    <Boton on:click={iniciaSesion}
                           disabled={Object.keys(errores).length && !cargando}>
                        Entrar
                    </Boton>
                {/if}
            </div>
        </Caja>
    {:else}
        <Caja>
            <div slot="titulo"><h2>Comprueba tu e-mail</h2></div>

            <p>Se ha enviado un e-mail a tu dirección con un enlace para acceder al panel.</p>
        </Caja>
    {/if}
</div>

{#if error}
    <Error on:cancel={() => error = null}>{error}</Error>
{/if}

<style>
    .login {
        max-width: 600px;
    }
</style>