<script>
    import {format, setDate, isBefore} from 'date-fns';
    import Fila from "./Fila.svelte";
    import store from '../../../../../../assets/src/Stores/Trabajadores/ver-trabajador-store';
    import Loading from "../../../../../../assets/src/Components/UI/Loading.svelte";

    const ahora = new Date();

    let fecha, calendario;

    $: cargando = $store.marcajes.cargando;
    $: fecha = $store.fecha;
    $: marcajes = $store.marcajes.marcajes;

    $: mes = $store.mes;
    $: dias = mes && Object.keys(mes);
</script>

<style>
    th:not(:nth-child(2)) {
        width: 1%;
    }

    :global(.marcajes .hora) {
        justify-content: flex-start !important;
    }

    :global(.marcajes .separador) {
        margin: 0 .5em;
    }
</style>

<table class="marcajes">
    <thead>
    <th>Días</th>
    <th>Marcajes</th>
    <th>Prev.</th>
    <th>Trab.</th>
    <th>Dif.</th>
    </thead>
    {#if cargando}
        <Loading/>
    {:else}
        <tbody>
        {#each dias as dia (fecha.getFullYear() + ' ' + dia)}
            <Fila tipo={mes[dia]} marcaje={marcajes[dia]} fecha={setDate(fecha, dia)}/>
        {/each}
        </tbody>
    {/if}
</table>