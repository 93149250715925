import axios from "../axios-control";

export const todos = () => axios
    .get('/motivos')
    .then(res => res.data)
    .catch(e => {
      if (e.response.status !== 401) {
        throw(e);
      }
    });

export const cargaUno = id => axios
    .get(`/motivos/${id}`)
    .then(res => res.data)
    .catch(e => {
      if (e.response.status !== 401) {
        throw(e);
      }
    });

export const actualiza = tipo => axios
    .put(`/motivos/${tipo.id}`, tipo)
    .then(res => res.data)
    .catch(e => {
      if (e.response.status !== 401) {
        throw(e);
      }
    });

export const crea = tipo => axios
    .post(`/motivos`, tipo)
    .then(res => res.data)
    .catch(e => {
      if (e.response.status !== 401) {
        throw(e);
      }
    });

export const eliminar = tipo => axios
    .delete(`/motivos/${tipo.id}`)
    .catch(e => {
      if (e.response.status !== 401) {
        throw(e);
      }
    });