<script>
    import {createEventDispatcher} from 'svelte';
    import {format, setDate} from 'date-fns';
    import {es} from 'date-fns/locale';
    import Dia from "./Dia.svelte";

    const dispatcher = createEventDispatcher();

    export let calendario;
    export let fecha;
    export let seleccionable = false;
    export let conTitulo = false;
    export let marcado = null;

    let vacios, dias;

    $: dias = calendario ? Object.keys(calendario).map(i => {
      return ({
        numero: i,
        fecha: setDate(fecha, i),
        tipo: calendario[i],
        seleccionado: calendario[i].seleccionado
      });
    }) : [];

    $: vacios = [...Array(format(fecha, 'i') - 1).keys()];

    const cabeceras = ['L', 'M', 'X', 'J', 'V', 'S', 'D'];

    const clickEnSemana = dia => dispatcher('click-en-dia-de-semana', {
        mes: fecha.getMonth() + 1,
        dia
    });
    const clickEnMes = mes => dispatcher('click-en-mes', mes);
</script>

<style>
    h2 {
        margin: .5em 0 -.5em;
    }

    h2, button {
        text-transform: uppercase;
        font-size: .9em;
        letter-spacing: .1em;
        font-weight: 600;
        text-align: center;
        font-family: inherit;
        border: 0;
        background: transparent;
        padding: 0;
    }

    h2 + .mes {
        padding-top: calc(var(--padding-global) - .5em);
    }

    .mes {
        display: grid;
        grid-template-columns: repeat(7, 1fr);
    }

    .cabecera {
        text-align: center;
        text-transform: uppercase;
        font-weight: 400;
        letter-spacing: .1em;
        padding: .5em;
        font-size: .9em;
        background: transparent;
        font-family: inherit;
        border: 0;
    }
</style>

{#if conTitulo }
    <h2>
        {#if seleccionable}
            <button on:click={() => clickEnMes(fecha.getMonth() + 1)}>
                {format(fecha, 'MMMM', {locale: es})}
            </button>
        {:else}
            {format(fecha, 'MMMM', {locale: es})}
        {/if}
    </h2>
{/if}

<div class="mes">
    {#each cabeceras as cabecera, i}
        {#if seleccionable}
            <button class="cabecera" tabindex="0"
                    on:click={() => clickEnSemana(i + 1)}>{cabecera}</button>
        {:else}
            <div class="cabecera">{cabecera}</div>
        {/if}
    {/each}

    {#each vacios as vacio (vacio)}
        <div class="vacio"></div>
    {/each}

    {#each dias as dia (dia.numero)}
        <Dia fecha={dia.fecha} {seleccionable} tipo={dia.tipo} seleccionado={dia.seleccionado}
             marcado={marcado && format(dia.fecha, 'Y-m-d') === format(marcado, 'Y-m-d')} on:click-en-dia/>
    {/each}
</div>