import {writable} from "svelte/store";
import {eliminar, todos} from '../Api/tipos-de-jornada';

const estadoInicial = {
  cargando: false,
  error: null,
  errorEliminacion: null,
  listado: [],
  listadoIndexado: {},
  tipoEliminando: null
};

const store = writable(estadoInicial);

export default {
  subscribe: store.subscribe,
  cargar: () => {
    const timeout = setTimeout(() => store.update(valor => ({...valor, cargando: true})), 100);

    store.update(() => estadoInicial);

    return todos()
        .then(listado => store.update(valor => ({
          ...valor,
          listado: listado,
          listadoIndexado: listado.reduce((acc, x) => ({[x.id]: x, ...acc}), {}),
          error: false
        })))
        .catch(e => store.update(valor => ({...valor, error: e})))
        .finally(() => {
          clearTimeout(timeout);
          store.update(valor => ({...valor, cargando: false}));
        });
  },
  eliminarTipo(tipo) {
    store.update(estado => ({
      ...estado,
      errorEliminacion: null,
      tipoEliminando: tipo
    }));
  },
  eliminaTipoDeVerdad(tipo) {
    return eliminar(tipo).then(() => {
      store.update(x => ({...x, tipoEliminando: null, errorEliminacion: null}));
      this.cargar();
    }).catch(e => store.update(x => ({...x, errorEliminacion: e.response.data})));
  }
};