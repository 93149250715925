<script>
  import Icono from "../UI/Icono.svelte";

  export let marcaje;

  function pad(num, size) {
    var s = "00" + num;
    return s.substr(s.length - size);
  }

  $: hora = marcaje.hora.split(':')[0]
  $: minutos = marcaje.hora.split(':')[1]
</script>

<style>
    .marcaje {
        display: inline-block;
        margin: .25em;
    }

    .marcaje .ico {
        display: inline-flex;
        border-radius: var(--radio-global);
        align-items: center;
        justify-content: center;
        padding: 5px;
    }

    .marcaje--actividad .ico {
        background: var(--verde);
    }

    .marcaje--ausencia .ico {
        background: var(--naranja);
    }
</style>

<span class={`marcaje marcaje--${marcaje.motivo.tipo.toLowerCase()}`}>
    <span class="ico">
        <Icono tamano="pequeno" externo titulo="{marcaje.motivo.nombre}"
               svg={marcaje.motivo.icono}/>
    </span>
    {#if hora > 23}
        {pad(hora % 24, 2)}:{minutos}+1d
    {:else}
        {marcaje.hora}
    {/if}
</span> {' '}