<script>
    import {format, isAfter, isBefore} from 'date-fns';
    import Duracion from "../../../../../../assets/src/Components/UI/Duracion.svelte";
    import Boton from "../../../../../../assets/src/Components/UI/Boton.svelte";
    import Advertencia from "../../../../../../assets/src/Components/UI/Advertencia.svelte";
    import {url} from '@sveltech/routify';
    import {nombreCompleto} from '../../../../../../assets/src/Modelos/Trabajador';

    import {horasTrabajadas} from '../../../../../../assets/src/Modelos/Trabajador';
    import Icono from "../../../../../../assets/src/Components/UI/Icono.svelte";
    import Diferencia from "../../../../../../assets/src/Components/UI/Diferencia.svelte";
    import Horas from "../../../../../../assets/src/Components/Marcajes/Horas.svelte";

    export let marcaje;
    export let fecha;
    export let tipo;
    export let trabajador = null;

    let trabajado, previsto, diferencia, entradas, salidas, incorrecta, horas, aviso;

    const ahora = new Date();

    let futuro = isAfter(fecha, ahora);

    $: {
        previsto = tipo.duracion || 0;

        if (marcaje) {
            horas = marcaje.horas;
            trabajado = horasTrabajadas(marcaje.horas);
            diferencia = trabajado - previsto;
            entradas = horas.filter(x => x.motivo.tipo === 'Actividad').length;
            salidas = horas.filter(x => x.motivo.tipo === 'Ausencia').length;
            incorrecta = isBefore(ahora, fecha) && entradas !== salidas;
            aviso = horas.filter(x => x.observaciones).length;
        } else {
            trabajado = entradas = salidas = 0;
            incorrecta = false;
            horas = [];
        }
    }
</script>

<style>
    tr {
        --color-linea: var(--naranja-clarito);
        font-family: var(--familia-monoespaciada);
    }

    td {
        vertical-align: middle;
    }

    .sin-datos {
        background: var(--naranja-clarito);
        --color-linea: #fff;
    }

    .fecha {
        white-space: nowrap;
        display: flex;
        align-items: center;
    }

    .incorrecta {
        font-weight: bold;
    }
</style>

<tr class:sin-datos={futuro || !previsto} class:incorrecta>
    <td>
        <div class="fecha">
            {format(fecha, 'dd/MM/yyyy')}
            {#if incorrecta}
                <Advertencia>Día con errores</Advertencia>
            {/if}
        </div>
    </td>
    {#if trabajador}
        <td class="trabajador">
            {nombreCompleto(trabajador)}
        </td>
    {/if}
    <td>
        {#if !previsto}
            {tipo.nombre}
        {:else }
            {#if aviso}
                <div style="float:right">
                    <Icono tamano="mediano" svg="hand-paper.svg" />
                </div>
            {/if}
            {#each horas as marcaje}
                <Horas {marcaje} />
            {/each}
        {/if}
    </td>

    {#if !tipo.duracion}
        <td colspan="4"></td>
    {:else}
        <td>
            <Duracion minutos={previsto}/>
        </td>
        {#if futuro}
            <td colspan="3"></td>
        {:else}
            <td>
                <Duracion minutos={trabajado}/>
            </td>
            <td>
                <Diferencia {previsto} {trabajado}/>
            </td>
        {/if}
    {/if}
</tr>