<script>
    import Menu from "../Menu.svelte";
    import hora from '../../../../assets/src/Stores/hora-store';
    import logo from '../../../../assets/src/img/logo.png';

    export let navegacion = true;
</script>

<style>
    .superior {
        width: 90%;
        max-width: var(--ancho);
        margin: 0 auto;
        padding: calc(var(--padding-global) / 2) 0;
        display: grid;
        grid-template-columns: 1fr max-content;
        align-items: end;
    }

    .logo {
        grid-column: 1;
    }

    .logo img {
        max-width: 33vw;
    }

    .fecha-y-hora {
        grid-column: 2;
    }

    @media (max-width: 400px) {
        .fecha, .hora {
            display: block;
            text-align: right;
        }
    }
</style>

<header>
    <div class="superior">
        <div class="logo">
            <img src={logo} alt="VoIP Futura Presencia">
        </div>

        <div class="fecha-y-hora">
            <span class="fecha">{$hora.fecha}</span>
            <span class="hora">{$hora.hora}</span>
        </div>
    </div>

    <Menu vacia={navegacion !== true}/>
</header>