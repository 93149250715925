let baseURL = 'https://presencia.mildmac.es/api';
//baseURL = 'https://control-horario.asedano.homeip.net/api';

import axios from 'axios';
import authStore from './Stores/auth-store';

let token, refreshToken;
authStore.subscribe(v => {
  token = v.token;
  refreshToken = v.refreshToken;
});

const instance = axios.create({
  withCredentials: true
});

instance.interceptors.request.use(config => {
  config.baseURL = baseURL;

  if (token) {
    config.headers['Authorization'] = `Bearer ${token}`
  }

  return config;
});

instance.interceptors.response.use(null, error => {
  const originalRequest = error.config,
  urlRefresh = `${baseURL}/token/refresh`;

  if (error.message === 'Cancelado a propósito') {
    throw error;
  }

  if (error.response.status === 401 && error.config.url === urlRefresh) {
    return window.history.replaceState(null, 'Login', '/login');
  }

  if (error.response.status === 401 && error.config.url !== '/login_check') {
    if (refreshToken && !originalRequest._retry && originalRequest.url.indexOf('refresh') === -1) {
      originalRequest._retry = true;

      return instance.post(
          urlRefresh,
          `refresh_token=${refreshToken}`,
          {
            headers: {
              'Content-Type': 'application/x-www-form-urlencoded'
            }
          })
          .then(res => {
            if (res.status === 200 && res.data.token) {
              authStore.setToken(res.data.token);
              originalRequest.headers['Authorization'] = `Bearer ${res.data.token}`;
              return axios(originalRequest);
            } else if (res.status === 401) {
              throw new Error();
            }
          });
    } else {
      return window.history.replaceState(null, 'Login', '/login');
    }
  }

  throw error;
});

export default instance;
export const inicializa = () => instance.get('/config/' + location.hostname).then(x => baseURL = x.data);