import {get, writable} from "svelte/store";
import {
  actualiza,
  actualizaDeGrupo,
  cargaDeEmpresa,
  cargaDeTrabajador,
  cargaDeGrupo,
  copiaDeTrabajador,
  copiaDeGrupo,
  restauraDeEmpresa,
  restauraDeGrupo
} from '../Api/calendarios';
import {addDays, format, isAfter, isBefore} from 'date-fns';

const estadoInicial = {
  cargando: false,
  guardando: false,
  error: null,
  calendario: null,
  tipo: null
};

const store = writable(estadoInicial);

export default {
  subscribe: store.subscribe,
  cargarDeTrabajador: (id, anio) => {
    const timeout = setTimeout(() => store.update(valor => ({...valor, cargando: true})), 300);

    store.update(() => estadoInicial);

    cargaDeTrabajador(id, anio)
        .then(res => {
          res.calendario = configuraCalendario(res.calendario);
          store.update(valor => ({...valor, ...res}));
        })
        .catch(e => {
          store.update(valor => ({...valor, error: e}));
        })
        .finally(() => {
          clearTimeout(timeout);
          store.update(valor => ({...valor, cargando: false}));
        });
  },
  cargarDeGrupo: (id, anio) => {
    const timeout = setTimeout(() => store.update(valor => ({...valor, cargando: true})), 300);

    store.update(() => estadoInicial);

    cargaDeGrupo(id, anio)
        .then(res => {
          res.calendario = configuraCalendario(res.calendario);
          store.update(valor => ({...valor, ...res}));
        })
        .catch(e => store.update(valor => ({...valor, error: e})))
        .finally(() => {
          clearTimeout(timeout);
          store.update(valor => ({...valor, cargando: false}));
        });
  },
  cargarDeEmpresa: anio => {
    const timeout = setTimeout(() => store.update(valor => ({...valor, cargando: true})), 300);

    store.update(() => estadoInicial);

    cargaDeEmpresa(anio)
        .then(res => {
          res.calendario = configuraCalendario(res.calendario);
          store.update(valor => ({...valor, ...res}));
        })
        .catch(e => store.update(valor => ({...valor, error: e})))
        .finally(() => {
          clearTimeout(timeout);
          store.update(valor => ({...valor, cargando: false}));
        });
  },
  actualizaCampo: (campo, valor) => {
    store.update(estado => ({
      ...estado,
      trabajador: {...estado.calendario, [campo]: valor}
    }));
  },
  invierteDias: ([inicio, fin]) => {
    let fecha = new Date(get(store).calendario.anio, 0, 1);

    return actualizaCalendario((accDias, mes, dia) => {
      const seleccionado = mes[dia].seleccionado;

      const retorno = {
        ...accDias,
        [dia]: {
          ...mes[dia],
          seleccionado: isAfter(fecha, inicio) && isBefore(fecha, fin) ? !seleccionado : seleccionado
        }
      };

      fecha = addDays(fecha, 1);
      return retorno;
    });
  },
  eligeDeTipo(tipo) {
    actualizaCalendario((accDias, mes, dia) => {
      const seleccionado = mes[dia].seleccionado;

      const retorno = {
        ...accDias,
        [dia]: {
          ...mes[dia],
          seleccionado: mes[dia].nombre === tipo ? !seleccionado : seleccionado
        }
      };

      return retorno;
    });
  },
  seleccionaDias(diasSeleccionados) {
    actualizaCalendario((accDias, mes, dia, fecha) => {
      const seleccionado = mes[dia].seleccionado,
          diaDeLaSemana = parseInt(format(fecha, 'i'), 10);

      return {
        ...accDias,
        [dia]: {
          ...mes[dia],
          seleccionado: diasSeleccionados.indexOf(diaDeLaSemana) >= 0 ? !seleccionado : seleccionado
        }
      };
    });
  },
  aplicaTipo(tipo, trabajador = null) {
    actualizaCalendario((accDias, mes, dia) => {
      return {
        ...accDias,
        [dia]: mes[dia].seleccionado ? {
          ...tipo,
          seleccionado: false
        } : mes[dia]
      };
    });

    store.update(x => {
      actualiza(x.calendario, preparaParaGuardar(x.calendario), trabajador)
          .then(() => store.update(x => ({...x, guardando: false})));

      return {...x, guardando: true};
    });
  },
  aplicaTipoAGrupo(tipo, grupo) {
    actualizaCalendario((accDias, mes, dia) => {
      return {
        ...accDias,
        [dia]: mes[dia].seleccionado ? {
          ...tipo,
          seleccionado: false
        } : mes[dia]
      };
    });

    store.update(x => {
      actualizaDeGrupo(x.calendario, preparaParaGuardar(x.calendario), grupo)
          .then(() => store.update(x => ({...x, guardando: false})));

      return {...x, guardando: true};
    });
  },
  seleccionarSemana(mesElegido, diaElegido) {
    actualizaCalendario((accDias, mes, dia, fecha) => {
      const seleccionado = mes[dia].seleccionado,
          diaDeLaSemana = parseInt(format(fecha, 'i'), 10);

      return {
        ...accDias,
        [dia]: {
          ...mes[dia],
          seleccionado: fecha.getMonth() + 1 === mesElegido && diaDeLaSemana === diaElegido ? !seleccionado : seleccionado
        }
      };
    });
  },
  seleccionarMes(mesElegido) {
    actualizaCalendario((accDias, mes, dia, fecha) => {
      const seleccionado = mes[dia].seleccionado;

      return {
        ...accDias,
        [dia]: {
          ...mes[dia],
          seleccionado: fecha.getMonth() + 1 === mesElegido ? !seleccionado : seleccionado
        }
      };
    });
  },
  restauraDeEmpresa(trabajador, anio) {
    store.update(x => ({...x, cargando: true}));
    restauraDeEmpresa(trabajador, anio)
        .then(calendario => {
          store.update(x => ({
            ...x,
            cargando: false,
            calendario: configuraCalendario(calendario.calendario),
            tipo: calendario.tipo
          }))
        })
        .catch(e => store.update(x => ({
          ...x,
          cargando: false,
          error: e
        })));
  },
  restauraDeGrupo(grupo, anio) {
    store.update(x => ({...x, cargando: true}));
    restauraDeGrupo(grupo, anio)
        .then(calendario => {
          store.update(x => ({
            ...x,
            cargando: false,
            calendario: configuraCalendario(calendario.calendario),
            tipo: calendario.tipo
          }))
        })
        .catch(e => store.update(x => ({
          ...x,
          cargando: false,
          error: e
        })));
  },
  copiarDeTrabajador(trabajador, anio, origen) {
    store.update(x => ({...x, cargando: true}));
    copiaDeTrabajador(trabajador, anio, origen)
        .then(calendario => {
          store.update(x => ({
            ...x,
            cargando: false,
            calendario: configuraCalendario(calendario.calendario),
            tipo: calendario.tipo
          }))
        })
        .catch(e => store.update(x => ({
          ...x,
          cargando: false,
          error: e
        })));
  },
  copiarDeGrupo(grupo, fullYear, detail) {
    store.update(x => ({...x, cargando: true}));
    copiaDeGrupo(grupo, anio, origen)
        .then(calendario => {
          store.update(x => ({
            ...x,
            cargando: false,
            calendario: configuraCalendario(calendario)
          }))
        })
        .catch(e => store.update(x => ({
          ...x,
          cargando: false,
          error: e
        })));
  }
}

const actualizaCalendario = cb => {
  store.update(estado => {
    const calendario = estado.calendario,
        meses = calendario.meses;

    let fecha = new Date(get(store).calendario.anio, 0, 1);

    return {
      ...estado,
      calendario: {
        ...calendario,
        meses: Object.keys(meses).reduce((accMeses, mes) => {
          const dias = Object.keys(meses[mes]);

          return {
            ...accMeses,
            [mes]: dias.reduce((accDias, dia) => {
              try {
                return cb(accDias, meses[mes], dia, fecha);
              } finally {
                fecha = addDays(fecha, 1);
              }
            }, {})
          };
        }, {})
      }
    };
  });
};

export const configuraCalendario = calendario => {
  return {
    ...calendario,
    meses: calendario.meses.map(dias => Object.keys(dias).reduce((acc, dia) => ({
      ...acc,
      [dia]: calendario.tipos[dias[dia]]
    }), {}))
  };
};

export const preparaParaGuardar = calendario => {
  const meses = Object.values(calendario.meses);
  return meses.map(dias => Object.keys(dias).reduce((acc, dia) => {
    return {...acc, [dia]: dias[dia].id};
  }, {}));
};