<script>
    import instance from '../../../assets/src/axios-control';
    import {onMount} from 'svelte';
    import {es} from 'date-fns/locale';
    import {format, parse} from 'date-fns';

    import {marcajes as cargaMarcajes} from '../../../assets/src/Api/trabajadores';
    import {todos as cargaMotivos} from '../../../assets/src/Api/motivos';

    import Loading from "../../../assets/src/Components/UI/Loading.svelte";
    import Horas from "../../../assets/src/Components/Marcajes/Horas.svelte";
    import Acciones from "../../../marcajes/src/Components/Acciones.svelte";
    import Error from "../../../assets/src/Components/UI/Error.svelte";

    export let trabajador;
    const fecha = new Date();
    let marcajes = null;
    let error = null;
    let enviando = false;

    let entradas = null;
    let salidas = null;

    const refresca = () => cargaMarcajes('yo', fecha.getFullYear(), fecha.getMonth() + 1)
            .then(x => marcajes = x[fecha.getDate()] ? x[fecha.getDate()].horas : []);

    onMount(() => {
        refresca();

        cargaMotivos()
                .then(res => {
                    const motivos = res.slice(2);
                    entradas = motivos.filter(x => x.tipo === 'Actividad');
                    salidas = motivos.filter(x => x.tipo === 'Ausencia');
                });
    })

    let posicion = null;
    if ("geolocation" in navigator) {
        navigator.geolocation.getCurrentPosition(position => {
            posicion = {
                lat: position.coords.latitude,
                lng: position.coords.longitude
            };
        });
    }

    const imputa = tipo => {
        enviando = true;
        instance.post(`marcaje`, {codigo: trabajador.codigo, tipo, posicion, origen: 'Ordenador'})
                .then(() => {
                    error = null;
                    refresca();
                })
                .catch(e => error = e)
                .finally(() => enviando = false)
    }
</script>

{#if marcajes === null || entradas === null || salidas === null}
    <Loading/>
{:else }
    <div class="acciones">
        <Acciones {entradas} {salidas} habilitado={!enviando}
                  on:imputa={({detail}) => imputa(detail)}/>
    </div>

    {#if marcajes.length === 0}
        <p>Todavía no tienes marcajes hoy</p>
    {:else}
        {#each marcajes as marcaje}
            <Horas {marcaje}/>
        {/each}
    {/if}
{/if}

{#if error}
    <Error on:cancel={() => error = null}>
        {error}
    </Error>
{/if}

<style>
    .acciones {
        margin-bottom: var(--padding-global);
    }
</style>