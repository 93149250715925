<script>
    import {onMount} from 'svelte';
    import {url} from '@sveltech/routify';
    import {addYears, startOfMonth, format, addDays, isAfter} from 'date-fns';
    import Titulo from "../../../assets/src/Components/UI/Titulo.svelte";
    import Caja from "../../../assets/src/Components/UI/Caja.svelte";
    import Subtitulo from "../../../assets/src/Components/UI/Subtitulo.svelte";
    import Navegacion from "../../../assets/src/Components/UI/Navegacion.svelte";
    import {nombreCompleto} from '../../../assets/src/Modelos/Trabajador';

    import Anio from "../../../assets/src/Components/Calendario/Anio.svelte";
    import Loading from "../../../assets/src/Components/UI/Loading.svelte";

    import store from '../../../assets/src/Stores/calendario-store';
    import storeTrabajador from '../../../assets/src/Stores/trabajador-store';
    import Tipos from "../../../assets/src/Components/Calendario/Tipos.svelte";
    import Boton from "../../../assets/src/Components/UI/Boton.svelte";
    import Estado from "../../../assets/src/Components/Calendario/Estado.svelte";
    import Migas from "../../../assets/src/Components/UI/Migas/Migas.svelte";
    import Miga from "../../../assets/src/Components/UI/Migas/Miga.svelte";
    import CopiarCalendario
        from "../../../assets/src/Components/Trabajadores/Calendario/CopiarCalendario.svelte";

    let fecha = startOfMonth(new Date());

    let calendario;

    $: store.cargarDeTrabajador('yo', fecha.getFullYear());
    $: calendario = $store.calendario;

    $: error = $store.error;

    const cambioDeAnio = e => fecha = addYears(fecha, e.detail);
</script>

<style>
    .contenido {
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: max-content;
        grid-gap: var(--padding-global);
    }

    @media (min-width: 1024px) {
        .contenido {
            grid-template-columns: 3fr minmax(20em, 1fr);
            grid-template-rows: max-content max-content max-content 1fr;
        }

        .calendario {
            grid-column: 1;
            grid-row: 1 / 4;
        }

        .estado {
            grid-column: 2;
            grid-row: 1;
        }
    }
</style>

<svelte:head>
    <title>Tu calendario</title>
</svelte:head>

<Titulo>Tu calendario</Titulo>

<div class="contenido">
    <div class="calendario">
        <Caja>
            <div slot="titulo">
                <Subtitulo>Tu calendario</Subtitulo>
            </div>

            <div slot="botones">
                <Navegacion etiqueta="izquierda" on:cambio={cambioDeAnio}>
                    Año {fecha.getFullYear()}
                </Navegacion>
            </div>

            {#if $store.calendario}
                <Anio {calendario} {fecha} />
            {:else}
                <Loading/>
            {/if}
        </Caja>
    </div>

    <div class="estado">
        <Estado {calendario}/>
    </div>
</div>