<script>
    export let margen = true;
</script>

<style>
    h1 {
        font-weight: 500;
        text-transform: uppercase;
        font-size: 1.2em;
        letter-spacing: .05em;
        margin: 2em 0 1em;
    }

    .sinMargen {
        margin: 0;
    }
</style>

<h1 class:sinMargen={!margen}><slot /></h1>