import axios from "../axios-control";
import usuario from '../Stores/usuario-store';

let token;
usuario.subscribe(valor => token = valor.token);

export const cargaDeTrabajador = (id, anio) => axios
    .get(`/calendarios/trabajador/${id}/${anio}`)
    .then(res => res.data)
    .catch(e => {
      if (e.response.status !== 401) {
        throw(e);
      }
    });

export const cargaDeGrupo = (id, anio) => axios
    .get(`/calendarios/grupo/${id}/${anio}`)
    .then(res => res.data)
    .catch(e => {
      if (e.response.status !== 401) {
        throw(e);
      }
    });

export const cargaDeEmpresa = anio => axios
    .get(`/calendarios/${anio}`)
    .then(res => res.data)
    .catch(e => {
      if (e.response.status !== 401) {
        throw(e);
      }
    });

export const actualiza = (calendario, meses, trabajador = null) => {
  const url = trabajador
      ? `/trabajadores/${trabajador.id}/actualiza-calendario/${calendario.anio}`
      : `/calendarios/${calendario.id}`;

  return axios
      .put(url, meses)
      .catch(e => {
        if (e.response.status !== 401) {
          throw(e);
        }
      });
};

export const actualizaDeGrupo = (calendario, meses, grupo) => {
  return axios
      .put(`/grupos/${grupo.id}/actualiza-calendario/${calendario.anio}`, meses)
      .catch(e => {
        if (e.response.status !== 401) {
          throw(e);
        }
      });
};

export const restauraDeEmpresa = (trabajador, anio) => axios
    .post(`/trabajadores/${trabajador.id}/restaura-calendario/${anio}`)
    .then(res => res.data)
    .catch(e => {
      if (e.response.status !== 401) {
        throw(e);
      }
    });

export const restauraDeGrupo = (grupo, anio) => axios
    .post(`/grupos/${grupo.id}/restaura-calendario/${anio}`)
    .then(res => res.data)
    .catch(e => {
      if (e.response.status !== 401) {
        throw(e);
      }
    });

export const copiaDeTrabajador = (trabajador, anio, origen) => axios
    .post(`/trabajadores/${trabajador.id}/copia-calendario/${origen.id}/${anio}`)
    .then(res => res.data)
    .catch(e => {
      if (e.response.status !== 401) {
        throw(e);
      }
    });

export const copiaDeGrupo = (grupo, anio, origen) => axios
    .post(`/grupos/${grupo.id}/copia-calendario/${origen.id}/${anio}`)
    .then(res => res.data)
    .catch(e => {
      if (e.response.status !== 401) {
        throw(e);
      }
    });
