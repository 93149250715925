<script>
  import {createEventDispatcher} from "svelte";
  import Boton from "./Boton.svelte";
  import {fade, fly} from 'svelte/transition';
  import Titulo from "./Titulo.svelte";
  import Icono from "./Icono.svelte";

  export let title;
  export let error;
  export let estrecho = false;

  const dispatch = createEventDispatcher();

  function closeModal() {
    dispatch("cancel");
  }
</script>

<style>
  .modal-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background: rgba(255, 255, 255, 0.95);
    z-index: 10;
  }

  .modal {
    position: fixed;
    top: 10vh;
    left: 10%;
    width: 80%;
    max-height: 80vh;
    background: white;
    border-radius: 5px;
    z-index: 100;
    box-shadow: 0 12px 12px -12px rgba(0, 0, 0, .2);
    overflow: auto;
    border: 1px solid var(--naranja-clarito);
    border-top: 4px solid var(--acentos);
    padding: var(--padding-global);
  }

  .modal.estrecho {
    max-width: 500px;
    left: 50%;
    transform: translatex(-50%);
  }

  .titulo {
    border-bottom: 1px solid var(--naranja-clarito);
    padding-bottom: var(--padding-global);
    margin-bottom: var(--padding-global);
  }

  .error {
    color: red;
    text-align: center;
  }

  footer {
    border-top: 1px solid var(--naranja-clarito);
    padding-top: var(--padding-global);
    margin-top: var(--padding-global);
    text-align: center;
  }

  @media (min-width: 768px) {
    .modal {
      width: 50rem;
      left: calc(50% - 25rem);
    }
  }
</style>

<div transition:fade class="modal-backdrop" on:click={closeModal} />
<div transition:fly={{y: 300}} class="modal" class:estrecho>
  <div class="titulo">
    <Titulo margen={false}>{title}</Titulo>
  </div>

  <div class="content">
    <slot />

    {#if error}
      <div class="error">{error}</div>
    {/if}
  </div>

  <footer>
    <slot name="footer">
      <Boton on:click={closeModal}>
        <Icono margen tamano={16}>close</Icono> Cerrar
      </Boton>
    </slot>
  </footer>
</div>
