<script>
    import Icono from "./Icono.svelte";
    import Duracion from "./Duracion.svelte";

    export let previsto = 0;
    export let trabajado = 0;
    export let iconoSiBien = false;

    let minutos, negativo, cero;

    $: minutos = trabajado - previsto;
    $: negativo = minutos < 0;
    $: positivo = minutos > 0;
    $: cero = minutos === 0;
    $: simbolo = cero ? '' : (negativo ? '-' : '+');
</script>

<style>
    .cero {
        color: var(--verde);
    }

    .positivo {
        color: var(--verde);
    }

    .negativo {
        color: var(--acentos);
    }
</style>

<span class:negativo class:cero class:positivo>
    {#if cero && iconoSiBien}
        <Icono tamano="gigante">{iconoSiBien}</Icono>
    {:else}
        {simbolo}<Duracion minutos={Math.abs(minutos)} />
    {/if}
</span>
