<script>
    export let controlType = null;
    export let id;
    export let label;
    export let rows = null;
    export let value;
    export let type = "text";
    export let valido = true;
    export let mensajeDeError = "";
    export let icono = false;
    export let disabled = false;

    let touched = false;
</script>

<style>
    .campo {
        border: 2px solid var(--bordes);
        border-radius: 3px 3px 0 0;
        display: flex;
    }

    .icono {
        flex: 0 0 auto;
        background: var(--bordes);
        display: flex;
        align-items: flex-start;
        justify-content: center;
        color: var(--negro);
        padding: .25rem;
    }

    input,
    textarea {
        display: block;
        width: 100%;
        font: inherit;
        border: none;
        background: white;
        padding: 0.25em 1em;
        transition: border-color 0.1s ease-out;
    }

    input:focus,
    textarea:focus {
        border-color: #e40763;
        outline: none;
    }

    label {
        display: block;
        margin-bottom: 0.5rem;
        width: 100%;
    }

    .form-control {
        padding: 0.5rem 0;
        width: 100%;
        margin: 0.25rem 0;
    }

    .invalid {
        border-color: var(--acentos);
    }

    .invalid .icono {
        background: var(--acentos);
    }

    .error-message {
        color: red;
        margin: 0.25rem 0;
    }

    .disabled textarea,
    .disabled input {
        opacity: .5;
    }
</style>

<div class="form-control">
    {#if label}
        <label for={id}>{label}</label>
    {/if}

    <div class="campo" class:invalid="{!valido && touched}" class:disabled>
        {#if icono}
            <div class="icono">
                <i class="material-icons">{icono}</i>
            </div>
        {/if}

        {#if controlType === 'textarea'}
            <textarea {rows} id={id} bind:value {disabled} on:input
                      on:blur={() => touched = true}></textarea>
        {:else}
            <input {type} id={id} {value} on:input {disabled}
                   on:blur={() => touched = true}/>
        {/if}
    </div>
    {#if mensajeDeError && !valido && touched}
        <p class="error-message">{mensajeDeError}</p>
    {/if}
</div>
