<script>
    import {onMount} from 'svelte';
    import {startOfMonth, parseJSON} from 'date-fns';
    import {url} from '@sveltech/routify';
    import {nombreCompleto} from '../../../../assets/src/Modelos/Trabajador';

    import store from '../../../../assets/src/Stores/Trabajadores/ver-trabajador-store';

    import Titulo from "../../../../assets/src/Components/UI/Titulo.svelte";
    import Trabajador from "../../Components/Trabajadores/Ver/Trabajador.svelte";
    import Calendario from "../../Components/Trabajadores/Ver/Calendario.svelte";
    import Marcajes from "../../Components/Trabajadores/Ver/Marcajes.svelte";
    import Loading from "../../../../assets/src/Components/UI/Loading.svelte";

    const id = 'yo';

    let fecha = startOfMonth(sessionStorage.fecha ? (parseJSON(sessionStorage.fecha)) : new Date());
    onMount(() => store.cargaDatos(id, fecha, true));

    const cambiaMes = e => store.avanzaMes(e.detail);

    let calendario, trabajador;
    $: calendario = $store.mes;
    $: trabajador = $store.trabajador.trabajador;
    $: fecha = $store.fecha || fecha;
    $: nombre = nombreCompleto(trabajador);
</script>

<style>
    .contenedor {
        display: grid;
        grid-gap: var(--padding-global);
    }

    @media (min-width: 768px) {
        .contenedor {
            grid-template-columns: repeat(2, 1fr);
        }

        .trabajador {
            grid-column: 1;
            grid-row: 1;
        }

        .calendario {
            grid-column: 2;
            grid-row: 1;
        }

        .marcajes {
            grid-column: 1 / 3;
            grid-row: 2;
        }
    }

    @media (min-width: 1280px) {
        .contenedor {
            grid-template-columns: minmax(20em, 1fr) 3fr;
            grid-template-rows: max-content 1fr;
        }

        .trabajador {
            grid-column: 1;
            grid-row: 1;
        }

        .calendario {
            grid-column: 1;
            grid-row: 2;
        }

        .marcajes {
            grid-column: 2;
            grid-row: 1 / 3;
        }
    }
</style>

<svelte:head>
    <title>Mis marcajes</title>
</svelte:head>

<Titulo>Mis marcajes</Titulo>

{#if $store.cargando}
    <Loading/>
{:else}
    <div class="contenedor">
        <div class="trabajador">
            {#if trabajador}
                <Trabajador {trabajador}/>
            {/if}
        </div>

        <div class="calendario">
            <Calendario {calendario} {trabajador} fecha={startOfMonth(fecha)}
                        on:cambio={cambiaMes}/>
        </div>

        <div class="marcajes">
            <Marcajes on:cambio={cambiaMes}/>
        </div>
    </div>
{/if}