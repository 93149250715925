<script>
    import Icono from "./Icono.svelte";

    export let icono = false;
</script>

<style>
    h2 {
        display: flex;
        align-items: center;
        margin: 0;
        font-size: .9em;
        text-transform: uppercase;
        font-weight: 600;
        letter-spacing: .1em;
    }
</style>

<h2>
    {#if icono}
        <Icono margen>{icono}</Icono>
    {/if}

    <slot/>
</h2>