import App from './App.svelte';

import axios, {inicializa} from '../../assets/src/axios-control';
import authStore from '../../assets/src/Stores/auth-store';

const token = /token=([^&]+)/.exec(window.location.search);
if (token && token.length === 2) {
  inicializa().then(() => {
    axios.get(`/login_trabajadores/${token[1]}`, {
      credentials: 'same-origin'
    })
        .then(x => {
              authStore.setToken(x.data.token)
          history.pushState(null, 'Panel de control', '/');
            }
        )
        .then(() => {
          const app = new App({
            target: document.body,
          });
        });
  });
} else {
  const app = new App({
    target: document.body,
  });
}