<script>
    import Icono from "../UI/Icono.svelte";

    export let hora;
</script>

<style>
    .hora {
        display: flex;
        align-items: center;
        justify-content: space-evenly;
        font-family: var(--familia-monoespaciada);
    }

    .entrada, .salida {
        display: inline-flex;
        align-items: center;
    }

    .entrada {
        color: var(--verde);
    }

    .salida {
        color: var(--acentos);
    }

    .separador {
        margin: 0 .25em;
    }
</style>

<div class="hora">
    <span class="entrada">
        <Icono margen tamano="pequeno">access_time</Icono>
        {hora.entrada.minimo ? `${hora.entrada.minimo}-${hora.entrada.maximo}` : hora.entrada}
    </span>

    <span class="separador">—</span>

    {#if hora.salida}
        <span class="salida">
            <Icono margen tamano="pequeno">access_time</Icono>
            {hora.salida.minimo ? `${hora.salida.minimo}-${hora.salida.maximo}` : hora.salida}
        </span>
    {/if}
</div>