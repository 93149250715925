<script>
    import {createEventDispatcher} from 'svelte';
    import {setMonth, format} from 'date-fns';
    import {es} from 'date-fns/locale';
    import Mes from "./Mes.svelte";

    export let calendario;
    export let fecha;
    export let seleccionable = false;

    const dispatcher = createEventDispatcher();

    $: meses = Object.keys(calendario.meses).map(mes => ({
        fecha: setMonth(fecha, mes),
        calendario: calendario.meses[mes],
    }));
</script>

<style>
    .anio {
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(14em, 1fr));
        grid-gap: var(--padding-global);
    }
</style>

<div class="anio">
    {#each meses as mes}
        <div class="mes">
            <Mes conTitulo calendario={mes.calendario} fecha={mes.fecha} {seleccionable}
                 on:click-en-dia on:click-en-dia-de-semana on:click-en-mes />
        </div>
    {/each}
</div>