import {get, writable} from 'svelte/store';
import {
  actualizaMarcaje as actualizaMarcajeDeTrabajador,
  creaMarcaje as creaMarcajeDeTrabajador,
  eliminaMarcaje as eliminaMarcajeDeTrabajador,
  cargaUno as cargaUnTrabajador,
  marcajes as cargaMarcajesDeTrabajador
} from "../../Api/trabajadores";
import {configuraCalendario} from "../calendario-store";
import {cargaDeTrabajador as cargaUnCalendario} from "../../Api/calendarios";
import {addMonths, isSameMonth, isSameYear} from 'date-fns';

const estadoInicial = {
  trabajador: {cargando: true, error: null, trabajador: null},
  calendario: {cargando: true, error: null, calendario: null, tipo: null},
  marcajes: {cargando: true, error: null, marcajes: null},
  mes: null,
  cargando: true,
  error: null,
  id: 0,
  fecha: null
};

const store = writable(estadoInicial);

const cargaTrabajador = id => {
  store.update(x => ({
    ...x,
    id,
    trabajador: {...x.trabajador, cargando: true, error: false, trabajador: null}
  }));

  return cargaUnTrabajador(id)
      .then(trabajador => {
        store.update(x => ({
          ...x,
          trabajador: {...x.trabajador, trabajador, error: false}
        }));
      })

      .catch(error => {
        store.update(x => ({
          ...x,
          trabajador: {...x.trabajador, error}
        }));
      });
};

const cargaTrabajadorSiHaceFalta = (id, forzar) => {
  if (get(store).id === id && !forzar) {
    return Promise.resolve();
  }

  return cargaTrabajador(id);
};

const cargaCalendario = (id, fecha) => {
  store.update(x => ({
    ...x,
    mes: null,
    calendario: {...x.calendario, cargando: true, error: false, calendario: null}
  }));

  return cargaUnCalendario(id, fecha.getFullYear())

      .then(calendario => {
        store.update(x => ({
          ...x,
          calendario: {...x.calendario, ...calendario, error: false}
        }));
      })

      .catch(error => {
        store.update(x => ({
          ...x,
          calendario: {...x.calendario, error}
        }));
      });
};

const cargaCalendarioSiHaceFalta = (id, fecha, forzar) => {
  const fechaActual = get(store).fecha;
  if (fechaActual && isSameYear(fecha, get(store).fecha) && get(store).id === id && !forzar) {
    return Promise.resolve();
  }

  return cargaCalendario(id, fecha);
};

const cargaMarcajes = (id, fecha) => {
  store.update(x => ({
    ...x,
    marcajes: {...x.marcajes, cargando: true, error: false, marcajes: null}
  }));

  return cargaMarcajesDeTrabajador(id, fecha.getFullYear(), fecha.getMonth() + 1)

      .then(marcajes => {
        store.update(x => ({
          ...x,
          marcajes: {...x.marcajes, marcajes, error: false}
        }));
      })

      .catch(error => {
        store.update(x => ({
          ...x,
          calendario: {...x.calendario, error}
        }));
      });
};

const cargaMarcajesSiHaceFalta = (id, fecha, forzar) => {
  const fechaActual = get(store).fecha;

  if (!forzar && fechaActual && isSameYear(fecha, fechaActual) && isSameMonth(fecha, fechaActual) && get(store).id === id) {
    return Promise.resolve();
  }

  return cargaMarcajes(id, fecha);
};

export default {
  subscribe: store.subscribe,
  cargaDatos: (id, fecha, forzar = false) => {
    Promise.all([
      cargaTrabajadorSiHaceFalta(id, forzar),
      cargaCalendarioSiHaceFalta(id, fecha, forzar),
      cargaMarcajesSiHaceFalta(id, fecha, forzar)
    ]).then(() => {
      store.update(x => ({
        ...x,
        fecha,
        cargando: false,
        mes: configuraCalendario(x.calendario.calendario).meses[fecha.getMonth()],
        error: x.trabajador.error || x.calendario.error || x.marcajes.error,
        trabajador: {...x.trabajador, cargando: false},
        calendario: {...x.calendario, cargando: false},
        marcajes: {...x.marcajes, cargando: false}
      }));
    });
  },
  avanzaMes(dias) {
    const datos = get(store),
        fecha = addMonths(datos.fecha, dias);

    this.cargaDatos(datos.id, fecha);
    sessionStorage.fecha = JSON.stringify(fecha);
  },
  setFecha(fecha) {
    store.update(x => ({...x, fecha}));
  },
  actualizaMarcaje(trabajador, fecha, original, nuevo) {
    actualizaMarcajeDeTrabajador(trabajador, fecha, original, nuevo)
        .then(() => this.cargaDatos(trabajador.id, fecha, true));
  },
  creaMarcaje(trabajador, fecha, nuevo) {
    creaMarcajeDeTrabajador(trabajador, fecha, nuevo)
        .then(() => this.cargaDatos(trabajador.id, fecha, true));
  },
  eliminaMarcaje(trabajador, fecha, original, nuevo) {
    eliminaMarcajeDeTrabajador(trabajador, fecha, original, nuevo)
        .then(() => this.cargaDatos(trabajador.id, fecha, true));
  }
};