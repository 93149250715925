<script>
    export let tamano = 'normal';
    export let margen = false;
    export let svg = false;
    export let externo = false;
    export let tipo = 'motivos';
    export let titulo = '';

    const tamanos = {
        pequeno: 14,
        mediano: 16,
        grande: 18,
        gigante: 60
    };

    let tamanoDeIcono = (typeof tamano === 'number') ? tamano : tamanos[tamano];
</script>

<style>
    .margen {
        margin-right: .25em;
    }

    .icono {
        display: inline-flex;
        align-items: center;
        justify-content: center;
    }

    .icono img {
        vertical-align: middle;
    }

    .material-icons {
        color: var(--color-icono);
    }
</style>

{#if svg}
    <span class="icono" class:margen>
        <img src={externo ? `https://presencia.mildmac.es/${tipo}/${svg}` : `/img/iconos/${svg}`} alt={titulo} width={tamanoDeIcono} height={tamanoDeIcono}>
    </span>
{:else}
    <div class="material-icons" class:margen style="font-size: {tamanoDeIcono}px">
        <slot/>
    </div>
{/if}