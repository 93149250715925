<script>
    import {onMount} from 'svelte';
    import storeTrabajadores from '../../../assets/src/Stores/trabajador-store';
    import storeCalendario from '../../../assets/src/Stores/calendario-store';
    import {diasIncorrectos} from '../../../assets/src/Api/marcajes';
    import {nombreCompleto} from '../../../assets/src/Modelos/Trabajador';

    import {url} from '@sveltech/routify';

    import Caja from "../../../assets/src/Components/UI/Caja.svelte";
    import ListadoDeTrabajadores
        from "../../../assets/src/Components/Trabajadores/Listado/index.svelte";
    import Loading from "../../../assets/src/Components/UI/Loading.svelte";

    import Titulo from "../../../assets/src/Components/UI/Titulo.svelte";
    import Subtitulo from "../../../assets/src/Components/UI/Subtitulo.svelte";

    import {addMonths, parse, format} from 'date-fns';
    import {es} from 'date-fns/locale';
    import Mes from "../../../assets/src/Components/Calendario/Mes.svelte";
    import Icono from "../../../assets/src/Components/UI/Icono.svelte";
    import CampoDeTexto from "../../../assets/src/Components/UI/CampoDeTexto.svelte";
    import Horas from "../../../assets/src/Components/Marcajes/Horas.svelte";
    import MisMarcajes from "../Components/MisMarcajes.svelte";

    const fecha = new Date();
    const meses = [...Array(3).keys()].map(x => addMonths(fecha, x));

    let dias = diasIncorrectos();
    let marcajes = null;

    onMount(() => {
        storeTrabajadores.cargar('yo');
        storeCalendario.cargarDeTrabajador('yo', fecha.getFullYear());
    });

    let trabajador = false;
    $: trabajador = $storeTrabajadores.trabajador;
</script>

<svelte:head>
    <title>Panel de control</title>
</svelte:head>

<Titulo>
    Bienvenido{#if trabajador.nombre}, {nombreCompleto(trabajador)}{/if}
</Titulo>

<div class="contenido">
    <div class="dias-incorrectos">
        <Caja>
            <div slot="titulo">
                <Subtitulo>Días con error</Subtitulo>
            </div>

            {#await dias}
                <Loading/>
            {:then lista}
                {#each lista as dia}
                    <div class="dia">
                        <div class="dia-cuenta">
                            <a href={$url(`/marcajes/${dia.fecha.substring(0, 7)}`)}>
                                {format(parse(dia.fecha, 'yyyy-MM-dd', new Date()), "d 'de' LLLL" , {locale: es})}
                            </a>
                        </div>
                    </div>
                {:else}
                    <div class="sin-errores">
                        <p>No hay ninguna incidencia</p>
                        <Icono tamano={60}>check</Icono>
                    </div>
                {/each}
            {:catch error}
                <p>Error cargando días</p>
            {/await}
        </Caja>
    </div>

    <div class="marcaje">
        <Caja>
            <div slot="titulo">
                <Subtitulo>Mis marcajes</Subtitulo>
            </div>

            {#if trabajador}
                <MisMarcajes {trabajador}/>
            {:else}
                <Loading/>
            {/if}
        </Caja>
    </div>

    <div class="calendario">
        <Caja>
            <div slot="titulo">
                <Subtitulo>Tu calendario</Subtitulo>
            </div>

            {#if $storeCalendario.cargando}
                <Loading/>
            {:else if $storeCalendario.error}
                <p>Error cargando calendario</p>
            {/if}

            {#if $storeCalendario.calendario }
                <div class="meses">
                    {#each meses as fecha}
                        <div>
                            <Mes calendario={$storeCalendario.calendario.meses[fecha.getMonth()]}
                                 {fecha} conTitulo/>
                        </div>
                    {/each}
                </div>
            {/if}
        </Caja>
    </div>
</div>


<style>
    .contenido {
        display: grid;
        grid-gap: var(--padding-global);
        grid-template-rows: max-content;
        grid-template-columns: 1.5fr 1fr;
    }

    .dias-incorrectos {
        grid-column: 2;
        grid-row: 1;
        --color-icono: var(--verde);
    }

    .sin-errores {
        text-align: center;
    }

    .marcaje {
        grid-column: 1;
        grid-row: 1;
    }

    .calendario {
        grid-column: 1;
        grid-row: 2;
    }

    .meses {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-gap: var(--padding-global);
    }

    .dia a {
        color: currentColor;
        text-decoration: none;
    }

    .dia {
        display: flex;
        justify-content: space-between;
    }

    .dia:not(:last-child) {
        margin-bottom: 1em;
    }

    .dia-nombre {
        font-weight: 500;
    }
</style>