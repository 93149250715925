<script>
    import {url, isActive} from '@sveltech/routify';

    export let vacia;

    let links;
    $: links = [
        ['/index', 'Panel de control', 'settings_system_daydream', $isActive('/index')],
        ['/marcajes', 'Mis marcajes', 'check', $isActive('/marcajes')],
        ['/calendario', 'Calendario', 'calendar_today', $isActive('/calendarios')],
        ['/logout', 'Cerrar sesión', 'exit_to_app', $isActive('/logout')]
    ];

    let activo = null;

    const activaLink = link => links = links.map(([url, titulo, icono])=> ([
        url, titulo, icono, url === link
    ]));

    const desactivaLink = () => links = links.map(([url, titulo, icono])=> ([
        url, titulo, icono, $isActive(url)
    ]));
</script>

<style>
    nav {
        background: var(--acentos);
        padding: 3px 0;
        font-weight: 500;
        box-shadow: 0 6px 6px -6px rgba(0, 0, 0, .3);
    }

    .menu {
        width: 90%;
        max-width: var(--ancho);
        margin: 0 auto;
        display: flex;
        list-style: none;
        padding: 0;
    }

    li {
        display: flex;
        align-items: center;
    }

    li:last-child {
        margin-left: auto;
    }

    a {
        padding: .5em;
        color: var(--blanco);
        text-decoration: none;
        display: inline-flex;
        align-items: center;
        transition: .3s;
    }

    .activo {
        background: #fff;
    }

    .activo a {
        color: var(--acentos);
    }

    .material-icons {
        margin-right: .25em;
    }
</style>

<nav>
    <ul class="menu">
        {#if vacia !== true}
            {#each links as [link, titulo, icono, activo] (link)}
                <li class:activo={activo}>
                    <a on:mouseover={() => activaLink(link)}
                       on:mouseleave={desactivaLink}
                               href={$url(link)} aria-current={activo}>
                        <i class="material-icons">{icono}</i> {titulo}
                    </a>
                </li>
            {/each}
        {:else}
            <li>&nbsp;</li>
        {/if}
    </ul>
</nav>