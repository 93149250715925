import {writable} from "svelte/store";
import {todos, eliminar} from '../Api/motivos';

const estadoInicial = {
  cargando: false,
  error: null,
  errorEliminacion: null,
  listado: [],
  listadoIndexado: {},
  ausencias: [],
  actividades: [],
  motivoEliminando: null
};

const store = writable(estadoInicial);

export default {
  subscribe: store.subscribe,
  cargar: () => {
    const timeout = setTimeout(() => store.update(valor => ({...valor, cargando: true})), 100);

    store.update(() => estadoInicial);

    todos()
        .then(listado => store.update(valor => ({
          ...valor,
          listado: listado,
          listadoIndexado: listado.reduce((acc, x) => ({[x.id]: x, ...acc}), {}),
          ausencias: listado.filter(x => x.tipo === 'Ausencia'),
          actividades: listado.filter(x => x.tipo === 'Actividad'),
          error: false
        })))
        .catch(e => store.update(valor => ({...valor, error: e})))
        .finally(() => {
          clearTimeout(timeout);
          store.update(valor => ({...valor, cargando: false}));
        });
  },
  eliminarTipo(tipo) {
    store.update(estado => ({
      ...estado,
      motivoEliminando: tipo,
      errorEliminacion: null
    }));
  },
  eliminaTipoDeVerdad(tipo) {
    eliminar(tipo).then(() => {
      store.update(x => ({...x, motivoEliminando: null, errorEliminacion: null}));
      this.cargar();
    }).catch(e => store.update(x => ({...x, errorEliminacion: e.response.data})));
  }
};