<script>
    import {fly} from 'svelte/transition';

    export let posicion = 'abajo';
</script>

<style>
    .tooltip {
        position: absolute;
        pointer-events: none;
        left: 50%;
        transform: translateX(-50%);
        background: var(--color);
        color: #fff;
        max-width: 15em;
        padding: calc(var(--padding-global) / 2);
        text-align: center;
        border-radius: var(--radio-global);
        box-shadow: 0 6px 6px -6px rgba(0, 0, 0, .4);
        z-index: 20;
    }

    .tooltip:after {
        content: '';
        display: block;
        width: 0;
        height: 0;
        border: 5px solid var(--color);
        position: absolute;
        left: calc(50% - 5px);
    }

    .tooltip.abajo {
        top: calc(100% + 5px);
    }

    .tooltip.abajo:after {
        border-left-color: transparent;
        border-right-color: transparent;
        border-top-color: transparent;
        bottom: 100%;
    }

    .tooltip.arriba {
        bottom: calc(100% + 5px);
    }

    .tooltip.arriba:after {
        border-left-color: transparent;
        border-right-color: transparent;
        border-bottom-color: transparent;
        top: 100%;
    }
</style>

<span class="tooltip {posicion}" transition:fly|local={{y: -10}}><slot /></span>
