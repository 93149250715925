<script>
    import {setDate, isBefore, format, isSameMonth} from 'date-fns';

    import {horasTrabajadas} from '../../../../../../assets/src/Modelos/Trabajador';

    import Duracion from "../../../../../../assets/src/Components/UI/Duracion.svelte";

    import store from '../../../../../../assets/src/Stores/Trabajadores/ver-trabajador-store';
    import Loading from "../../../../../../assets/src/Components/UI/Loading.svelte";

    let previstas, trabajadas, titulo, mes, dias, fecha, calendario, marcajes;

    const ahora = new Date();

    $: fecha = $store.fecha;
    $: if (!$store.marcajes.cargando && !$store.calendario.cargando) {
        mes = $store.mes;
        dias = Object.keys(mes);

        previstas = dias
                .filter(x => !isSameMonth(fecha, ahora) || isBefore(setDate(fecha, x), ahora))
                .reduce((acc, dia) => acc + (mes[dia].duracion || 0), 0);

        marcajes = $store.marcajes.marcajes;

        trabajadas = Object.keys(marcajes)
                .filter(x => isBefore(setDate(fecha, marcajes[x].dia), ahora))
                .reduce((acc, x) => acc + horasTrabajadas(marcajes[x].horas), 0);
    } else {
      previstas = trabajadas = 0;
    }

    titulo = isSameMonth(fecha, ahora) ? `Horas hasta el ${format(ahora, 'dd/MM/yyyy')}` : 'Horas';
</script>

<style>
    .resumen {
        margin: calc(var(--padding-global) / 2) 0 var(--padding-global);
        width: 15em;
        margin-left: auto;
        border: 1px solid var(--naranja-clarito);
        box-shadow: 0 6px 6px -6px rgba(0, 0, 0, .2);
        border-radius: var(--radio-global);
        padding: var(--padding-global);
        box-sizing: content-box;
    }

    tfoot {
        color: var(--acentos);
    }
</style>

<div class="resumen">
    <table>
        <thead>
        <th colspan="2">{titulo}</th>
        </thead>
        <tbody>
        <tr>
            <th>Previstas</th>
            <td>
                <Duracion minutos={previstas}/>
            </td>
        </tr>
        <tr>
            <th>Trabajadas</th>
            <td>
                <Duracion minutos={trabajadas}/>
            </td>
        </tr>
        </tbody>
        <tfoot>
        <tr>
            <th>Diferencia</th>
            <td>
                <Duracion minutos={previstas - trabajadas}/>
            </td>
        </tr>
        </tfoot>
    </table>
</div>
