import axios from "../axios-control";

export const login = (email, clave) => {
  const post = { username: email, password: clave };
  return axios.post('/login_check', post).then(resp => resp.data);
};

export const pideClave = (codigo) => {
  return axios.get(`/trabajadores/login/${codigo}?format=json`, {
    withCredentials: true
  }).then(resp => resp.data);
};