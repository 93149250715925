<script>
    import Icono from "./Icono.svelte";
    import Tooltip from "./Tooltip.svelte";

    let tooltip = false;
</script>

<style>
    .error {
        --color: var(--acentos);
        color: var(--acentos);
        margin-left: .5em;
        position: relative;
        line-height: 1;
    }
</style>

<span class="error" on:mouseover={() => tooltip = true} on:mouseleave={() => tooltip = false}>
    <Icono>warning</Icono>
    {#if tooltip}
        <Tooltip posicion="arriba"><slot/></Tooltip>
    {/if}
</span>