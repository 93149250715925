<script>
    import Caja from "../../../../../assets/src/Components/UI/Caja.svelte";
    import Subtitulo from "../../../../../assets/src/Components/UI/Subtitulo.svelte";

    import {nombreCompleto} from '../../../../../assets/src/Modelos/Trabajador';

    export let trabajador;
    $: nombre = nombreCompleto(trabajador);
</script>

<Caja>
    <div slot="titulo">
        <Subtitulo icono="face">{nombre}</Subtitulo>
    </div>

    <dl>
        <dt>Código</dt>
        <dd>{trabajador.codigo}</dd>
        <dt>Teléfono</dt>
        <dd>{trabajador.telefono}</dd>
        <dt>NIF</dt>
        <dd>{trabajador.nif}</dd>
        {#if trabajador.grupo}
            <dt>Grupo</dt>
            <dd>{trabajador.grupo.nombre}</dd>
        {/if}
    </dl>
</Caja>

<style>
    dt, dd {
        display: inline;
        margin: 0;
        padding: 0;
    }

    dt {
        font-weight: 600;
    }

    dt:after {
        content: ':';
        margin-right: .5em;
    }

    dd:after {
        content: '\A';
        white-space: pre;
    }
</style>