import axios from "../axios-control";
import {format} from "date-fns";

export const diasIncorrectos = () => axios
    .get('marcajes/dias-incorrectos')
    .then(res => res.data)
    .catch(e => {
      if (e.response.status !== 401) {
        throw(e);
      }
    });

export const diasIncorrectosDeGrupo = id => axios
    .get('marcajes/dias-incorrectos/grupo/' + id)
    .then(res => res.data)
    .catch(e => {
      if (e.response.status !== 401) {
        throw(e);
      }
    });

export const descarga = (inicio, fin, trabajadores, agrupacion, sentido, tipoDeIncidencia, motivo, sede) => {
  return axios.post('marcajes/descarga', {
    inicio: format(inicio, 'yyyy-MM-dd'),
    fin: format(fin, 'yyyy-MM-dd'),
    trabajadores: trabajadores.map(x => x.id),
    agrupacion,
    sentido,
    tipoDeIncidencia,
    motivo,
    sede
  }, {
    responseType: 'blob'
  });
};