<script>
    import {createEventDispatcher} from 'svelte';

    const dispatch = createEventDispatcher();

    import Boton from "./Boton.svelte";

    export let etiqueta = 'centro';
    export let botones = true;

    const atrasa = () => dispatch('cambio', -1);
    const adelanta = () => dispatch('cambio', 1);
</script>

<style>
    .navegacion {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .texto {
        text-transform: uppercase;
        font-size: .9em;
        letter-spacing: .15em;
        font-weight: 500;
        margin: 0 var(--padding-global);
    }

    .izquierda .texto {
        order: -1;
        margin: 0;
    }

    .izquierda .anterior,
    .derecha .siguiente {
        margin: 0 calc(var(--padding-global) / 2);
    }

    .derecha .texto {
        order: 2;
        margin: 0;
    }

    .sin-botones {
        justify-content: center;
    }
</style>

<div class="navegacion {etiqueta}" class:sin-botones={!botones}>
    {#if botones}
        <span class="anterior">
            <Boton on:click={atrasa} icono="keyboard_arrow_left">Retroceder</Boton>
        </span>
    {/if}

    <span class="texto"><slot /></span>

    {#if botones}
        <span class="siguiente">
            <Boton on:click={adelanta} icono="keyboard_arrow_right">Avanzar</Boton>
        </span>
    {/if}
</div>