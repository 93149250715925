<script>
    import {Router} from '@sveltech/routify';
    import {routes} from "@sveltech/routify/tmp/routes";
    import storeTiposDeJornada from '../../assets/src/Stores/tipos-de-jornada-store';
    import storeMotivos from '../../assets/src/Stores/motivos-store';
    import {inicializa} from "../../assets/src/axios-control";
    import Loading from "../../assets/src/Components/UI/Loading.svelte";

    let inicializado = false;
    inicializa().then(() => {
        let stores = [storeTiposDeJornada, storeMotivos];
        stores.forEach(x => x.cargar());
        inicializado = true;
    });
</script>

<style>
    :global(:root) {
        --naranja: #ff6600;
        --blanco: #fffffc;
        --negro: #000;
        --naranja-clarito: #ffe0cc;
        --verde: #26A892;
        --rojo: #F5001D;
        --gris: #999999;


        --fondo: var(--blanco);
        --acentos: var(--naranja);
        --bordes: var(--naranja-clarito);
        --color-del-texto: var(--negro);
        --tamano-del-texto: 14px;
        --familia-del-texto: Roboto, sans-serif;
        --familia-monoespaciada: 'Roboto Mono', monospace;

        --padding-global: 1.75em;
        --ancho: 90rem;
        --alto-de-linea: 1.5;
        --radio-global: 5px;

        --padding-vertical-botones: .5rem;
        --padding-horizontal-botones: .66rem;
    }

    @media (max-width: 767px) {
        :global(:root) {
            --padding-global: 1em;
        }
    }

    :global(*), :global(*:before), :global(*:after) {
        box-sizing: inherit;
    }

    :global(html) {
        box-sizing: border-box;
        color: var(--color-del-texto);
        overflow-y: scroll;
        overflow-x: hidden;
        background: var(--fondo);
    }

    :global(html, body) {
        margin: 0;
        padding: 0;
        font-size: var(--tamano-del-texto);
        font-family: var(--familia-del-texto);
        line-height: var(--alto-de-linea);
    }

    :global(p) {
        margin-top: 0;
    }

    :global(p:last-child) {
        margin-bottom: 0;
    }

    :global(h1, h2, h3) {
        font-family: var(--familia-del-texto);
    }

    :global(main) {
        width: 90%;
        max-width: var(--ancho);
        margin: 0 auto var(--padding-global);
    }

    :global(table) {
        width: 100%;
        border-collapse: collapse;
        font-size: inherit;
    }

    :global(thead th) {
        border-bottom: 2px solid var(--acentos);
    }

    :global(th) {
        padding: .25em;
        text-align: left;
        vertical-align: top;
        font-weight: 500;
    }

    :global(tfoot th, tbody th, td) {
        padding: .5em;
        text-align: left;
        vertical-align: top;
        border-bottom: 1px solid var(--acentos);
    }

    :global(input[type="radio"]) {
        visibility: hidden;
    }

    :global(input[type="radio"] + label) {
        display: flex;
        align-items: center;
    }

    :global(input[type="radio"] + label:before) {
        content: '';
        display: inline-block;
        width: 1.2em;
        height: 1.2em;
        border-radius: 50%;
        border: 2px solid var(--acentos);
        margin-right: .5em;
    }

    :global(input[type="radio"]:checked + label:before) {
        background: radial-gradient(circle at center, var(--acentos), var(--acentos) calc(.6em - 4px), transparent 0) center no-repeat;
    }

    @media (max-width: 1040px) {
        :global(:root) {
            --tamano-del-texto: 13px;
        }
    }

    @media (min-width: 1111px) and (max-width: 1280px) {
        :global(:root) {
            --tamano-del-texto: 1.25vw;
        }
    }
</style>

{#if inicializado}
    <Router {routes}/>
{:else}
    <Loading />
{/if}