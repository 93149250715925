import {format} from 'date-fns';
import {es} from 'date-fns/locale'
import {readable} from "svelte/store";

const nuevaFecha = () => {
  const fecha = new Date();
  return {
    fecha: format(fecha, "d 'de' MMMM 'de' yyyy", {locale: es}),
    hora: format(fecha, 'HH:mm:ss')
  }
};

export default readable(nuevaFecha(), set => {
  setInterval(() => set(nuevaFecha()), 1000);
});