import validate from 'validate.js';
import {validaNif} from "../Utils/Validadores/Nif";
import {differenceInMinutes, parseJSON} from 'date-fns';

validate.validators.nif = (str = '') => {
  return str.length === 0 || validaNif(str.replace('-', '')).valid ? null : 'es inválido';
};


const constraints = {
  nombre: {
    presence: {
      allowEmpty: false,
      message: 'es obligatorio'
    }
  },
  apellidos: {
    presence: {
      allowEmpty: false,
      message: 'es obligatorio'
    }
  },
  codigo: {
    presence: {
      allowEmpty: false,
      message: 'es obligatorio'
    }
  },
  email: {
    presence: {
      allowEmpty: false,
      message: 'es obligatorio'
    },
    email: {
      message: 'Por favor, comprueba el e-mail'
    }
  },
  nif: {
    presence: {
      allowEmpty: false,
      message: 'es obligatorio'
    },
    nif: {
      message: 'es inválido'
    }
  },
};

export const validaTrabajador = trabajador => (
    (trabajador ? validate(trabajador, constraints) : {}) || {}
);

export const nombreCompleto = trabajador => (
    (trabajador && trabajador.nombre) ? `${trabajador.nombre} ${trabajador.apellidos}` : 'Trabajador'
);

export const horasTrabajadas = horas => {
  let entrada = null;
  let salida = null;
  let minutos = 0;

  horas.forEach(marcaje => {
    if (marcaje.motivo.tipo === 'Actividad') {
      entrada = entrada || parseJSON(`2020-01-01T${marcaje.hora}:00.000Z`);
    } else {
      salida = parseJSON(`2020-01-01T${marcaje.hora}:00.000Z`);
      minutos += differenceInMinutes(salida, entrada);
      entrada = null;
    }
  });

  return minutos;
};