<script>
    import Boton from "../../../assets/src/Components/UI/Boton.svelte";
    import {createEventDispatcher} from 'svelte';
    import Icono from "../../../assets/src/Components/UI/Icono.svelte";

    const dispatch = createEventDispatcher();

    export let habilitado;
    export let entradas;
    export let salidas;

    const imputa = tipo => _ => dispatch('imputa', tipo);
</script>

<div class="botones">
    <Boton tipo="ok" ancho disabled={!habilitado}
           on:click={imputa('Entrada')}>
        <Icono svg="Entrada-blanco.svg" tamano={40} margen/>
        Entrada
    </Boton>

    <Boton ancho disabled={!habilitado} on:click={imputa('Salida')}>
        <Icono svg="Salida-blanco.svg" tamano={40} margen/>
        Salida
    </Boton>
</div>

<div class="mas-botones">
    {#each entradas as motivo}
        <div class="boton">
            <Boton ancho tipo={motivo.tipo === 'Actividad' ? 'ok' : null} disabled={!habilitado} on:click={imputa(motivo.nombre)}>
                <Icono svg={motivo.icono} tamano={40} margen externo/>
            </Boton>
        </div>
    {/each}
    {#each salidas as motivo}
        <div class="boton">
            <Boton ancho tipo={motivo.tipo === 'Actividad' ? 'ok' : null} disabled={!habilitado} on:click={imputa(motivo.nombre)}>
                <Icono svg={motivo.icono} tamano={40} margen externo/>
            </Boton>
        </div>
    {/each}
</div>

<style>
    .botones {
        display: grid;
        grid-gap: 18px;
        grid-template-columns: 1fr 1fr;
        font-size: 5vw;
    }

    @media only screen and (min-width: 720px) {
        :root {
            --padding-global: 1.75em;
        }

        .botones {
            font-size: 1.7em;
        }
    }

    .mas-botones {
        display: grid;
        margin-top: 9px;
        grid-template-columns: repeat(auto-fit, minmax(45px, 1fr));
        grid-column-gap: 18px;
    }

    .mas-botones .boton {
        font-size: 1.5em;
    }
</style>
