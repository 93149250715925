<script>
    import Tooltip from "../UI/Tooltip.svelte";
    import Icono from "../UI/Icono.svelte";
    import EntradaSalida from "../Horas/EntradaSalida.svelte";
    import Duracion from "../UI/Duracion.svelte";

    export let tipo;
</script>

<style>
    .titulo {
        white-space: nowrap;
    }

    .horario {
        margin-top: .5em;
    }
</style>

<Tooltip posicion="arriba">
    <div class="titulo">
        <Icono margen
               tamano="pequeno">{tipo.duracion ? 'access_time' : 'timer_off'}
        </Icono>

        {tipo.nombre}
        {#if tipo.duracion}
            (<Duracion minutos={tipo.duracion}/>)
        {/if}
    </div>

    {#if tipo.horas}
        <div class="horario">
            {#each tipo.horas as hora (hora.entrada)}
                <EntradaSalida hora={hora}/>
            {/each}
        </div>
    {/if}
</Tooltip>