<script>
    import Boton from "./Boton.svelte";
    import {slide} from 'svelte/transition';
    import Icono from "./Icono.svelte";

    export let cabecera = true;
    export let pie = true;
    export let sombra = true;
    export let alturaMaxima = null;

    export let plegable = false;

    let plegado = true;
</script>

<style>
    .caja {
        --altura-maxima: 1000000px;
        border: 1px solid var(--bordes);
        border-radius: var(--radio-global);
        border-top: 4px solid var(--acentos);
        padding: var(--padding-global);
        width: 100%;
        display: flex;
        flex-direction: column;
        max-width: 90vw;
        background: var(--fondo);
    }

    .contenedor {
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }

    .caja.scroll .contenido {
        overflow-y: auto;
        padding-right: var(--padding-global);
    }

    .sombra {
        box-shadow: 0 6px 6px -6px rgba(0, 0, 0, .2);
    }

    .contenido {
        width: 100%;
        overflow-x: auto;
        max-height: var(--altura-maxima);
    }

    header {
        display: grid;
        align-items: center;
        grid-template-columns: 1fr max-content;
        grid-gap: var(--padding-global);
        border-bottom: 1px solid var(--naranja-clarito);
        padding-bottom: 1em;
        margin-bottom: 1em;
    }

    footer {
        border-top: 1px solid var(--naranja-clarito);
        margin-top: 1em;
        padding-top: 1em;
    }

    footer:empty {
        display: none;
    }

    @media (min-width: 1024px) {
        .caja:not(.scroll) .contenido {
            overflow: initial;
        }
    }
</style>

<div class="caja" class:sombra={sombra} class:scroll={alturaMaxima} style={alturaMaxima ? `--altura-maxima:${alturaMaxima}` : ''}>
    {#if cabecera}
        <header>
            <slot name="titulo"/>

            <div class="botones">
                <slot name="botones"/>

                {#if plegable}
                    <Boton tamano="pequeno" tipo="transparente"
                           on:click={() => plegado = !plegado}>
                        <Icono>{plegado ? 'keyboard_arrow_down' : 'keyboard_arrow_up'}</Icono>
                    </Boton>
                {/if}
            </div>
        </header>
    {/if}

    {#if !plegable || !plegado}
        <div transition:slide|local class="contenedor">
            <div class="contenido">
                <slot/>
            </div>

            {#if pie}
                <footer>
                    <slot name="pie"/>
                </footer>
            {/if}
        </div>
    {/if}
</div>