import {writable} from "svelte/store";

const esValido = valor => ['null', 'false', 'undefined'].indexOf(valor) === -1;

const estadoInicial = {
  token: esValido(sessionStorage.token) ? sessionStorage.token : null,
  refreshToken: esValido(localStorage.refreshToken) ? localStorage.refreshToken : null
};

const store = writable(estadoInicial);

store.subscribe(({token, refreshToken}) => {
  sessionStorage.token = token;
  localStorage.refreshToken = refreshToken;
});

export default {
  subscribe: store.subscribe,
  set: valores => store.update(x => ({...x, ...valores})),
  setToken: token => store.update(x => ({...x, token})),
  setRefreshToken: refreshToken => store.update(x => ({...x, refreshToken})),
  logout() {
    store.update(valor => ({
      token: undefined,
      refreshToken: undefined
    }));
  }
}