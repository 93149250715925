<script>
    import Icono from "./Icono.svelte";
    import {fly} from 'svelte/transition';
    import Tooltip from "./Tooltip.svelte";

    export let href = null;
    export let type = 'button';
    export let disabled = false;
    export let icono = null;
    export let tipo = 'normal';
    export let tamano = 'normal';
    export let radio = true;
    export let ancho = false;

    let titulo = false;
</script>

<style>
    .boton {
        --color: var(--acentos);
        position: relative;
        display: inline-flex;
        align-items: center;
        transition: .3s;
    }

    .boton > :focus,
    .boton > :hover,
    .boton > :focus,
    .boton > :hover {
        box-shadow: 0 8px 7px -5px rgba(0, 0, 0, .2);
    }

    .boton.ok {
        --color: var(--verde);
    }

    .boton.peligro {
        --color: var(--rojo);
    }

    .boton.neutral {
        --color: var(--gris);
    }

    .boton.transparente {
        --color: transparent;
    }

    .boton.transparente button,
    .boton.transparente a {
        color: var(--acentos);
    }

    button, a {
        background: var(--color);
        border: 0;
        padding: var(--padding-vertical-botones) var(--padding-horizontal-botones);
        color: #fff;
        text-transform: uppercase;
        font-family: inherit;
        transition: .3s;
        font-size: .8em;
        letter-spacing: 1px;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        text-decoration: none;
        border-radius: 0;
    }

    .grande button,
    .grande a {
        padding: calc(var(--padding-vertical-botones) * 2) calc(var(--padding-horizontal-botones) * 2);
    }

    .pequeno button,
    .pequeno a {
        padding: calc(var(--padding-vertical-botones) / 2) var(--padding-horizontal-botones);
    }

    .radio button, .radio a {
        border-radius: var(--radio-global);
    }

    button[disabled] {
        opacity: .3;
        cursor: not-allowed;
    }

    .ancho,
    .ancho button,
    .ancho a {
        display: flex;
        align-items: center;
        width: 100%;
    }
</style>

<span class="boton {tipo} {tamano}" class:radio class:ancho
      on:mouseover={() => titulo = true}
      on:mouseleave={() => titulo = false}>
    {#if href}
        <a class:icono={icono} href={disabled ? 'javascript:void(0)' : href}
           on:focus={() => titulo = true} on:blur={() => titulo = false}>
            {#if icono}
                <Icono tamano={tamano}>{icono}</Icono>
            {:else}
                <slot/>
            {/if}
        </a>
    {:else}
        <button class:icono={icono} {type} on:click {disabled}
                on:focus={() => titulo = true} on:blur={() => titulo = false}>
            {#if icono}
                <Icono tamano={tamano}>{icono}</Icono>
            {:else}
                <slot/>
            {/if}
        </button>
    {/if}

    {#if icono && titulo}
        <Tooltip posicion="arriba"><slot/></Tooltip>
    {/if}
</span>