<script>
    import {url} from '@sveltech/routify';

    import {format} from 'date-fns';
    import {es} from 'date-fns/locale';

    import Boton from "../../../../../assets/src/Components/UI/Boton.svelte";
    import Caja from "../../../../../assets/src/Components/UI/Caja.svelte";
    import Subtitulo from "../../../../../assets/src/Components/UI/Subtitulo.svelte";

    import Mes from "../../../../../assets/src/Components/Calendario/Mes.svelte";

    import Navegacion from "../../../../../assets/src/Components/UI/Navegacion.svelte";

    export let navegacion = true;
    export let fecha;
    export let calendario;
    export let marcado = null;
</script>

<Caja>
    <div slot="titulo">
        <Subtitulo icono="calendar_today">Calendario</Subtitulo>
    </div>

    <Navegacion on:cambio botones={navegacion}>
        {format(fecha, 'MMMM yyyy', {locale: es})}
    </Navegacion>

    <Mes {calendario} {fecha} {marcado} on:click-en-dia on:click-en-dia-de-semana on:click-en-mes/>
</Caja>